
import { defineComponent, ref, Ref } from 'vue'

export default defineComponent({
  setup() {
    const test: Ref<any> = ref('Coming Soon...')

    return { test }
  }
})
